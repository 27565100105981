import React from 'react'
import Layout from "../components/Layout"
import { graphql, useStaticQuery } from 'gatsby'
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Script } from "gatsby"
import { Link } from "gatsby"


import { convertToBgImage } from "gbimage-bridge"




const GbiBridged = () => {
  const { aboutImage, logoImage } = useStaticQuery(
    graphql`
      query {
        aboutImage: file(relativePath: { eq: "lamiaautowrap-stevan.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2698
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        logoImage: file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        
      }
      
    `
  )
  const image = getImage(aboutImage)
  const image2 = getImage(logoImage)



  // Use like this:
  const bgImage = convertToBgImage(image)


  



  return (
    
    <Layout>
 
    

 
    <main>
      <div id="content" className="subp">
      <section className="row text-center headings">
      <h1 className='h1-heading'>Kontakta oss</h1>
 

      </section>
     
      <section className="row box">

      <div className="col col-6">
        <div className="inner-content"> 
        <h2>Stevan Pavic</h2>
       <span>
        Telefon: <a className="link" href="tel:+46704934931">070 - 493 49 31</a><br/>
        E-post: <a className="link" href="mailto:lamiaautowrap@gmail.com">lamiaautowrap@gmail.com</a>
      </span>
  
       </div>

      </div>
      <div className="col col-6">
        <GatsbyImage image={image} alt={"image"} className="content-image" width="1000"/>

      </div>

      </section>
      
     <section className="row box">

      <div className="col col-12 text-center">
      <div className="inner-content"> 
      <h2 className="text-center mb-3 wide">

      Hitta hit
      </h2>
      Vi finns på <a className="link" href="https://goo.gl/maps/CxeWM3uqfJ9ntVq37" target="_blank">Ringvägen 9, 567 33 Vaggeryd</a>

      <div class="map"><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2143.9304599627408!2d14.135202116277794!3d57.496549581055476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4650a1faedcf8965%3A0xb42cb010cd70c491!2sRingv%C3%A4gen%209%2C%20567%2033%20Vaggeryd%2C%20Sweden!5e0!3m2!1sen!2sus!4v1652711597074!5m2!1sen!2sus" width="960" height="400" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>





    </div>
      </div>

</section>

     </div>
     </main>
   

  </Layout>


  )
}

export default GbiBridged


